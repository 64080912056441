import {getLocalStorage} from "./local-storage";

export function authHeader() {
    let user = getLocalStorage("user", true)

    if (user && user.token) {
        return {'Authorization': user.token};
    } else {
        return {};
    }
}
