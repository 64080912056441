/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState, useCallback} from "react";
import axios from "axios";
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import {Link} from "react-router-dom";

const apiUrl = process.env.REACT_APP_API;

const Reset = (props) => {
    let {token} = props.match.params
    const [form] = Form.useForm();
    const [state, setState] = useState({
        errorMessage: '',
        loader: true,
        formView: false,
        success: false,
    })
    const init = useCallback(() => {
        if (token !== undefined && token !== null && token !== '') {

            tokenCheck(token).then(payload => {
                if (payload === 200) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            loader: false,
                            formView: true
                        }
                    })
                } else {
                    window.location.href = `/not-found`
                }
            })
        } else {
            window.location.href = `/not-found`;
        }
    }, [
        token
    ])
    useEffect(() => {
        document.body.className = 'page-reset';
        init()
        return () => {
            document.body.className = '';
        }
    }, [init]);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not valid'
        },
    };
    const tokenCheck = async (t) => {
        let postData = {
            token: t
        };
        let res = await axios.post(`${apiUrl}/center/forgotpassword/check-token`, postData);
        let {data} = res;
        if (data.error === null) {
            return 200;
        } else {
            return data.error.code
        }
    }
    const ResetPassword = async v => {
        let password = v.password;
        let repassword = v.repassword;
        if (password !== repassword) {
            form.setFields([
                {
                    name: 'repassword',
                    errors: ['Password and Repeat Password don`t match'],
                },
            ]);
            return false
        }
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        let loaderBox = document.getElementById('loader-box');
        loaderBox.classList.add('transparent')
        let postData = {
            token: token,
            password,
            repassword,
        };
        let res = await axios.post(`${apiUrl}/web/center/forgotpassword/new-password`, postData);
        let {data} = res;
        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    success: true
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    errorMessage: data.error.message ? data.error.message : 'Something went wrong',
                    loader: false
                }
            })
        }
    }

    let {loader, errorMessage, success} = state
    return (
        <>
            {loader ? (
                <div id="loader-box" className="loader-box">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <div className="page-container">
                <div className="content-wrapper">
                    {success ? (
                        <>
                            <h2>Thanks, your password is changed</h2>
                            <div className="description">You can log in to your account</div>
                            <Link to="/login"
                                  className="btn btn-big whit-border border-color-pink bg-pink color-white">Login</Link>
                        </>
                    ) : (
                        <div className="form">
                            <Form
                                form={form}
                                name="reset_form"
                                onFinish={ResetPassword}
                                onFinishFailed={onFinishFailed}
                                validateMessages={validateMessages}
                            >
                                <div className="form-box">
                                    <h1 className="">Password Recovery</h1>
                                    <div className="description">
                                        Create a new password below
                                    </div>

                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        rules={[{required: true}, {
                                            min: 8,
                                            message: 'Password must be minimum 8 characters.'
                                        }]}
                                        className="password-filed-box"
                                    >
                                        <Input.Password
                                            visibilityToggle={false}
                                            placeholder="Password"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="repassword"
                                        label="Repeat Password"
                                        rules={[{required: true}, {
                                            min: 8,
                                            message: 'Password must be minimum 8 characters.'
                                        }]}
                                        className="password-filed-box"
                                    >
                                        <Input.Password
                                            visibilityToggle={false}
                                            placeholder="Repeat Password"
                                        />
                                    </Form.Item>

                                    {errorMessage ? (
                                        <div className="response-box">
                                            {errorMessage}
                                        </div>
                                    ) : null}

                                    <Form.Item shouldUpdate={true}>
                                        {() => (
                                            <button
                                                className="btn btn-big whit-border border-color-pink bg-pink color-white"
                                                htmltype="submit">
                                                Send
                                            </button>
                                        )}
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Reset;
