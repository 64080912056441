/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";

import axios from "axios";
import {authHeader} from "../../../../_helpers";
import Pagination from 'antd/lib/pagination';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';

const apiUrl = process.env.REACT_APP_API;
const {Text} = Typography

const InvitedStudentList = (props) => {
    const [state, setState] = useState({
        errorMessage: '',
        loader: true,
        list: [],
        listTotal: 0,
        currentPage: 1,
        pp: 10,
    })

    useEffect(() => {
        document.body.className = 'page-invited-students';
        getStudentList().then(res => {
            let {code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: payload.list,
                        listTotal: payload.count
                    }
                })
            } else {

            }
        })
        return () => {
            document.body.className = '';
        }
    }, []);

    const getStudentList = async (start = 0, end = 10) => {
        const queryParams = {
            _start: start,
            _end: end,
        };
        const res = await axios.get(`${apiUrl}/center/announced-students`, {
            params: queryParams,
            headers: authHeader()
        })

        const {data} = res;
        if (data.error === null) {
            let {rows, count} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }

    const onChange = page => {
        const {pp} = state;

        let start = (page - 1) * pp,
            end = pp * page;

        setState(prevState => {
            return {
                ...prevState,
                currentPage: page,
            }
        })

        getStudentList(start, end).then(res => {
            let {code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: payload.list,
                        listTotal: payload.count
                    }
                })
            } else {

            }
        })
    }

    const {loader, currentPage, list, listTotal, pp} = state
    return (
        <>
            {loader ? (
                <div className="loader-box">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}

            <div className="student-box student-box-invited">
                <div className="content-wrapper">
                    <h2>Invited Students List</h2>
                    {list.length ? (
                        <div className="student-list">
                            {Object.keys(list).map(listKey => {
                                let {registered, email} = list[listKey]

                                return (
                                    <div key={listKey} className="student-list-item">
                                        <div className="email">
                                            <Tooltip placement="topLeft" title={email}>
                                                <Text ellipsis={true}>
                                                    {email}
                                                </Text>
                                            </Tooltip>
                                        </div>

                                        <div className="registered">
                                            {registered ? (
                                                <Tooltip placement="topLeft"
                                                         title="This student has accepted the invitation (look in the Registered Students List section)">
                                                    <span className="green">Invitation Accepted</span>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip placement="topLeft"
                                                         title="You have sent this student an invitation">
                                                    <span className="orange">Invitation Pending</span>
                                                </Tooltip>
                                            )}
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div className="student-list-empty">
                            No students invited yet
                        </div>
                    )}
                    {listTotal > 0 ? (
                        <Pagination
                            size="small"
                            defaultPageSize={pp}
                            className="ant-table-pagination ant-table-pagination-center"
                            current={currentPage}
                            onChange={onChange}
                            total={listTotal}
                        />
                    ) : null}
                </div>
            </div>
        </>
    );
}

export default InvitedStudentList;
