/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import axios from "axios";
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';

const apiUrl = process.env.REACT_APP_API;

const Forgot = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        errorMessage: '',
        loader: false,
        success: false,
        email:''
    })
    useEffect(() => {
        document.body.className = 'page-forgot';
        return () => {
            document.body.className = '';
        }
    }, []);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not valid'
        },
    };

    const onFinish = async v => {
        let {email} = v
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })

        let postData = {
            email
        };
        let res = await axios.post(`${apiUrl}/center/forgotpassword/index`, postData);
        let {data} = res;
        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    success: true,
                    email
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    errorMessage: data.error.message ? data.error.message : 'Something went wrong',
                    loader: false
                }
            })
        }
    };

    const resendMail = async () => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        let {email} = state
        let postData = {
            email,
            type: "forgotpassword"
        }
        let res = await axios.post(`${apiUrl}/web/center/token/reset`, postData);
        let {data} = res;
        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    success: true,
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    errorMessage: data.error.message ? data.error.message : 'Something went wrong',
                    loader: false
                }
            })
        }
    }
    let {loader, errorMessage, success} = state
    return (
        <>
            {loader ? (
                <div id="loader-box" className="loader-box transparent">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}

            <div className="page-container">
                <div className="content-wrapper">
                    {!success ? (
                        <div className="form">
                            <Form
                                form={form}
                                name="login_form"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                validateMessages={validateMessages}
                            >
                                <div className="form-box">
                                    <h1 className="">Forgot Password</h1>
                                    <div className="description">
                                        Write your the email you used to register to Space Panda to reset your password.
                                    </div>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{type: 'email', required: true}]}
                                    >
                                        <Input
                                            placeholder="Write your Email"
                                        />
                                    </Form.Item>
                                    {errorMessage ? (
                                        <div className="response-box">
                                            {errorMessage}
                                        </div>
                                    ) : null}
                                    <Form.Item shouldUpdate={true}>
                                        {() => (
                                            <button
                                                className="btn btn-big whit-border border-color-pink bg-pink color-white"
                                                htmltype="submit">
                                                Send
                                            </button>
                                        )}
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <>
                            <h2>Thanks, your email is verified</h2>
                            <div className="description">You can log in to your account</div>
                            <div className="resend-box">
                                Didn't get email? <span onClick={resendMail}>Resend</span>
                            </div>
                        </>
                    )}

                </div>
            </div>
        </>
    );
}

export default Forgot;
