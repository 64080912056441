/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import Form from "antd/lib/form";
import axios from "axios";
import {authHeader} from "../../../../../_helpers";
import Input from "antd/lib/input";

const apiUrl = process.env.REACT_APP_API;

const PaypalPayment = (props) => {
    let {fieldData, paymentOptionKey} = props
    const [form] = Form.useForm();

    const [state, setState] = useState({
        loader: false,
        success: false,
        errorMessage: false
    })

    useEffect(() => {
        if (fieldData) {
            let {
                email
            } = fieldData
            form.setFieldsValue({
                email
            })
        }
        return () => {
        }
    }, [fieldData, form]);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not valid'
        },
    };

    const onFinish = async v => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        let {
            email
        } = v

        const postData = {
            key: paymentOptionKey,
            email
        }
        const res = await axios.put(`${apiUrl}/center/payoptions`, postData, {
            headers: authHeader()
        })

        const {data} = res

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    success: true
                }
            })

            setTimeout(() => {
                setState(prevState => {
                    return {
                        ...prevState,
                        success: false
                    }
                })
            }, 3000)
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    errorMessage: data.error.message ? data.error.message : 'Something went wrong',
                }
            })
        }
    }
    let {errorMessage, loader, success} = state

    return (
        <>
            {loader ? (
                <div className="loader-box transparent">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <Form
                form={form}
                name="login_form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                scrollToFirstError={true}
            >
                <div className="form-box">

                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{type: 'email', required: true}]}
                    >
                        <Input
                            placeholder="Write your PayPal email"
                        />
                    </Form.Item>

                    {errorMessage ? (
                        <div className="response-box">
                            {errorMessage}
                        </div>
                    ) : null}
                    {success ? (
                        <div className="response-box success">
                            Payment method successfully updated
                        </div>
                    ) : null}
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <button
                                className="btn btn-big whit-border border-color-pink bg-pink color-white"
                                htmltype="submit">
                                Save
                            </button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}

export default PaypalPayment;
