/* eslint-disable no-template-curly-in-string */
import Profile from "./components/pages/profile";
import Dashboard from "./components/pages/dashboard";

const privateRoutes = [
    {
        path: `/profile`,
        pathname: "/profile",
        exact: true,
        name: "Profile",
        id: "profile",
        component: Profile
    },
    {
        path: `/`,
        pathname: "/",
        exact: true,
        name: "Student Inner",
        id: "studentInner",
        component: Dashboard
    },
];

export default privateRoutes;
