/* eslint-disable no-template-curly-in-string */
import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import meta from './public/meta.json';

function MetaTags(props) {
    const [page] = useState(props.page);
    const [metaData] = useState(meta[page])

    return (
        <Helmet>
            <title>{metaData.title}</title>

            {
                Object.keys(metaData).map((key, index) => {
                    if (key.includes("og")) {
                        return (
                            <meta key={index} property={key} content={metaData[key]}/>
                        )
                    }
                    return (
                        <meta key={index} name={key} content={metaData[key]}/>
                    )
                })
            }
            <meta property="og:type" content="website"/>
            <meta name="og:type" content="website"/>
            <meta property="og:url" content={window.location.href}/>
            <meta property="fb:app_id" content="your_app_id"/>
        </Helmet>
    )
}

export default MetaTags
