/* eslint-disable no-template-curly-in-string */
import React, {useState, useEffect, useCallback} from 'react';
import axios from "axios";
import {setLocalStorage} from "../../../_helpers";

const apiUrl = process.env.REACT_APP_API;

function Verify(props) {
    const [showLoader, setLoaderState] = useState(true);
    const [token] = useState(props.match.params.token)
    const [userType] = useState(props.match.params["userType"])
    const [timer, setTimer] = useState(5);

    const tokenCheck = useCallback(() => {
        if (token !== undefined && token !== null && token !== '') {
            ConfirmAccount(token, userType).then(res => {
                const {code, payload} = res
                if (code === 200) {
                    setLoaderState(false)
                    setLocalStorage('user', payload, true)
                } else {
                    window.location.href = `/not-found`
                }
            })
        } else {
            window.location.href = `/not-found`;
        }
    }, [
        token,
        userType
    ])

    const ConfirmAccount = async (t, usertype) => {
        let postData = {
            token: t
        };
        let res = await axios.post(`${apiUrl}/web/center/confirm`, postData);
        let {data} = res;
        if (data.error === null) {
            return {
                code: 200,
                payload: data.payload
            };
        } else {
            return data.error.code
        }
    }

    useEffect(() => {
        document.body.className = 'page-verify';
        window.scroll(0, 0);
        tokenCheck();
        return () => {
            document.body.className = '';
        }
    }, [tokenCheck]);

    useEffect(() => {
        if (!showLoader) {
            if (timer === 0) {
                window.location.reload(false);
            }
            setTimeout(function () {
                setTimer(timer - 1)
            }, 1000)
        }
    })
    return (
        <>
            {showLoader ? (
                <div id="loader-box" className="loader-box">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <div className="page-container">
                <div className="content-wrapper">
                    <h2>Thanks, your email is verified</h2>
                    <div className="description">You can log in to your account</div>
                    <div onClick={() => {
                        window.location.reload(false);
                    }} className="btn btn-big whit-border border-color-pink bg-pink color-white">Login
                    </div>
                    <div className="redirect-counter">
                        You will be logged in automatically in {timer} seconds.
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verify;
