/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select'
import axios from "axios";
import {authHeader} from "../../../../../_helpers";
import PhoneInput from "react-phone-input-2";

const apiUrl = process.env.REACT_APP_API;

const CheckPayment = (props) => {
    let {fieldData, paymentOptionKey} = props
    const [form] = Form.useForm();

    const [state, setState] = useState({
        loader: false,
        CountriesList: [],
        success: false,
        errorMessage: false
    })

    useEffect(() => {
        if (fieldData) {
            let {
                fullName,
                address1,
                address2,
                city,
                state,
                zipCode,
                countryId,
                phone
            } = fieldData
            form.setFieldsValue({
                fullName,
                address1,
                address2,
                city,
                state,
                zipCode,
                countryId,
                phone
            })
        }
        getCountriesList().then(res => {
            if (res) {
                setState(prevState => {
                    return {
                        ...prevState,
                        CountriesList: res,
                    }
                })
            }
        })
        return () => {
        }
    }, [fieldData, form]);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not valid'
        },
    };

    const getCountriesList = async () => {
        let queryParams = {}
        queryParams._start = 0
        queryParams._end = 250
        queryParams.search = ''

        let res = await axios.get(`${apiUrl}/countries/list`, {
            params: queryParams
        });
        let {data} = res;

        if (data.error === null) {
            return data.payload
        }
    }

    const onFinish = async v => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        let {
            fullName,
            address1,
            address2,
            city,
            state,
            zipCode,
            countryId,
            phone
        } = v

        const postData = {
            key: paymentOptionKey,
            fullName,
            address1,
            address2,
            city,
            state,
            zipCode,
            countryId,
            phone
        }
        const res = await axios.put(`${apiUrl}/center/payoptions`, postData, {
            headers: authHeader()
        })

        const {data} = res

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    success: true
                }
            })

            setTimeout(() => {
                setState(prevState => {
                    return {
                        ...prevState,
                        success: false
                    }
                })
            }, 3000)
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    errorMessage: data.error.message ? data.error.message : 'Something went wrong',
                }
            })
        }
    }
    let {errorMessage, loader, CountriesList, success} = state

    return (
        <>
            {loader ? (
                <div className="loader-box transparent">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <Form
                form={form}
                name="login_form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                scrollToFirstError={true}
            >
                <div className="form-box">

                    <Form.Item
                        name="fullName"
                        label="Pay to the order of (Full Name)"
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder="Write your full name"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Address line 1"
                        name="address1"
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder="Street address or P.O. Box"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Address line 2"
                        name="address2"
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder="Apt, Suite Unit, Building"
                        />
                    </Form.Item>

                    <Form.Item
                        label="City"
                        name="city"
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder="Write your city"
                        />
                    </Form.Item>

                    <Form.Item
                        label="State/Province"
                        name="state"
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder="Write your state/province"
                        />
                    </Form.Item>

                    <Form.Item
                        label="ZIP"
                        name="zipCode"
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder="Write ZIP Code"
                        />
                    </Form.Item>

                    <Form.Item
                        name="countryId"
                        label="Country"
                        rules={[{required: true}]}
                    >

                        <Select
                            virtual={false}
                            autoComplete={new Date().valueOf()}
                            showSearch
                            optionFilterProp="children"
                            placeholder="Select Country"
                            allowClear
                            showArrow
                        >
                            {Object.keys(CountriesList).map((key) => {
                                let listItem = CountriesList[key]
                                return (
                                    <Select.Option key={key}
                                                   value={listItem.id}>{listItem.countryName}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={
                            <>
                                <span>Phone</span>
                                <span>(Optional)</span>
                            </>
                        }
                        name="phone"
                    >
                        <PhoneInput
                            autoComplete={new Date().valueOf()}
                            inputProps={{
                                name: 'phone',
                            }}
                            inputClass="ant-input"
                            country={'us'}
                            type="tel"
                            tabIndex="3"
                            countryCodeEditable={false}
                        />
                    </Form.Item>
                    {errorMessage ? (
                        <div className="response-box">
                            {errorMessage}
                        </div>
                    ) : null}
                    {success ? (
                        <div className="response-box success">
                            Payment method successfully updated
                        </div>
                    ) : null}
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <button
                                className="btn btn-big whit-border border-color-pink bg-pink color-white"
                                htmltype="submit">
                                Save
                            </button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}

export default CheckPayment;
