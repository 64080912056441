/* eslint-disable no-template-curly-in-string */
import React from 'react';
import {Router, Route, Switch, Redirect} from "react-router-dom";
import routes from "./routes";
import privateRoutes from "./privateRoutes";
import {
    history,
    getLocalStorage, setLocalStorage, removeLocalStorageItem
} from "./_helpers";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import './Assets/css/style.css'
import axios from "axios";
import {signout} from "./actions";

const apiUrl = process.env.REACT_APP_API;
const App = () => {
    let user = getLocalStorage("user", true)
    const deviceId = getLocalStorage('deviceId')
    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    axios.interceptors.response.use(
        response => {
            return response;
        },
        err => {
            const originalRequest = err.config;

            if (err.response.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({resolve, reject});
                    })
                        .then(token => {

                            originalRequest.headers['Authorization'] = token;
                            return axios(originalRequest);
                        })
                        .catch(err => {
                            return Promise.reject(err);
                        });
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise(function (resolve, reject) {
                    let aaa = getLocalStorage("user", true)
                    let refreshToken = aaa.refreshToken;

                    axios
                        .post(`${apiUrl}/center/refresh-token`, {
                            refreshToken: refreshToken,
                            uniqueId: deviceId
                        })
                        .then(tokenRefreshResponse => {
                            if (tokenRefreshResponse.data.error === null) {
                                let userToken = tokenRefreshResponse.data.payload.token;
                                let userRefreshToken = tokenRefreshResponse.data.payload.refreshToken;
                                let newData = {
                                    token: userToken,
                                    refreshToken: userRefreshToken,
                                    centerName: aaa.centerName
                                };
                                processQueue(null, userToken);
                                removeLocalStorageItem("user");
                                setLocalStorage("user", newData, true)
                                axios.defaults.headers.common['Authorization'] = userToken;
                                originalRequest.headers['Authorization'] = userToken;
                                resolve(axios(originalRequest));
                                return Promise.resolve();
                            } else {
                                signout()
                                window.location.reload()
                            }
                        })
                        .then(() => {
                            isRefreshing = false;
                        });
                });
            }

            return Promise.reject(err);
        }
    );
    return (
        <>
            {
                user ?
                    <Router history={history}>
                        <Header/>
                        <Switch>
                            {privateRoutes.map((route, idx) => {
                                return route.component ? (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={props => (
                                            <route.component {...props} key={new Date().getTime()}/>
                                        )}
                                    />
                                ) : null;
                            })}

                            <Redirect to={{pathname: `/`}}/>
                        </Switch>
                        <Footer/>
                    </Router>
                    :
                    <Router history={history}>
                        <Header/>
                        <Switch>
                            {routes.map((route, idx) => {
                                return route.component ? (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={props => (
                                            <route.component {...props} key={new Date().getTime()}/>
                                        )}
                                    />
                                ) : null;
                            })}

                            <Redirect to={{pathname: `/login`}}/>
                        </Switch>
                        <Footer/>
                    </Router>
            }

        </>
    );
}

export default App;
