/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from "react";
import MailingTemplates from '../../../../Assets/images/mailing-templates.png'
import Form from "antd/lib/form";
import {ReactMultiEmail, isEmail} from 'react-multi-email';
import 'react-multi-email/style.css';
import axios from "axios";
import {authHeader, getLocalStorage} from "../../../../_helpers";

const apiUrl = process.env.REACT_APP_API;

const MailComponent = (props) => {
    const {centerName} = getLocalStorage('user', true)
    const [form] = Form.useForm();

    const [state, setState] = useState({
        errorMessage: '',
        loader: false,
        success: false,
        emails: []
    })

    useEffect(() => {
        return () => {
        }
    }, []);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not valid'
        },
    };

    const sendMailList = async v => {
        let {emails} = state
        if (emails.length === 0) {
            form.setFields([
                {
                    name: 'email',
                    validating: false,
                    errors: ['Field must contain at least one email address.'],
                },
            ]);
            return true
        }
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        let postData = {
            emails
        }
        let res = await axios.post(`${apiUrl}/center/announce`, postData, {
            headers: authHeader()
        })

        let {data} = res

        if (data.error === null) {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    success: true,
                    emails: []
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    errorMessage: data.error.message ? data.error.message : 'Something went wrong',
                }
            })
        }
    }

    const sendManual = () => {
        let a = document.createElement('a');
        const subject = 'Invitation to participate in #1 Online Math Olympiad powered by Educators';
        const body = `Dear Mathlete's Parent,%0D%0A%0D%0A We are beyond excited to announce our partnership with Space Panda International Math Olympiads and invite you to participate.%0D%0A%0D%0A Learn more about 2022 Competition Serries, Annual Olympiad, and Math Ranking at: %0D%0Ahttps://www.spacepandamath.org!.%0D%0A%0D%0A Do not forget to select ${centerName} at the registration to get extra benefits.%0D%0A%0D%0A Always devoted to your child's education,%0D%0A${centerName}`;
        a.setAttribute('href', `mailto:?subject=${subject}&body=${body}`)
        a.setAttribute('target', '_blank')
        a.click()
        a.remove()
    }

    let {errorMessage, loader, success, emails} = state

    return (
        <>
            {loader ? (
                <div className="loader-box transparent">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <div className="mailing-box">
                <div className="content-wrapper">
                    <div className="image-box">
                        <img src={MailingTemplates} alt={''}/>
                    </div>
                    <div className="info-box">
                        <h2>Send Announcement Email</h2>
                        <div className="text">
                            We’ve prepared a promotional email template (see on the left) to send to your students,
                            encouraging them to participate in the Olympiad. Please add their emails below separated by
                            a
                            coma and we’ll send the email to them.
                        </div>

                        <Form
                            form={form}
                            name="mailing_form"
                            onFinishFailed={onFinishFailed}
                            validateMessages={validateMessages}
                            scrollToFirstError={true}
                            onFinish={sendMailList}
                        >
                            <div className="form-box">
                                <Form.Item label="Email" name="email">
                                    <ReactMultiEmail
                                        placeholder="email address 1, email address 2…"
                                        emails={emails}
                                        onChange={(_emails) => {
                                            setState(prevState => {
                                                return {
                                                    ...prevState,
                                                    emails: _emails
                                                }
                                            })
                                            form.setFields([
                                                {
                                                    name: 'email',
                                                    validating: true,
                                                    errors: ['']
                                                }
                                            ])
                                        }}
                                        validateEmail={email => {
                                            return isEmail(email); // return boolean
                                        }}
                                        getLabel={(
                                            email: string,
                                            index: number,
                                            removeEmail: (index: number) => void,
                                        ) => {
                                            return (
                                                <div data-tag key={index}>
                                                    {email}
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                                </div>
                                            );
                                        }}
                                    />
                                </Form.Item>
                                {errorMessage ? (
                                    <div className="response-box">
                                        {errorMessage}
                                    </div>
                                ) : null}
                                {success ? (
                                    <div className="response-box success">
                                        Emails successfully sent
                                    </div>
                                ) : null}
                                <Form.Item shouldUpdate={true}>
                                    {() => (
                                        <button
                                            className="btn btn-medium whit-border border-color-pink bg-pink color-white"
                                            htmltype="submit">
                                            Send
                                        </button>
                                    )}
                                </Form.Item>

                                <div className="manual-wrap">
                                    <span onClick={sendManual}>or send manually</span>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MailComponent;
