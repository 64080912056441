/* eslint-disable no-template-curly-in-string */
import React, {useEffect} from "react";
import MailComponent from './mail'
import StudentList from "./student";
import InvitedStudentList from "./invitedStudents"
const Dashboard = (props) => {
    useEffect(() => {
        document.body.className = 'page-dashboard';


        return () => {
            document.body.className = '';
        }
    }, []);


    return (
        <>
            <div className="page-container">
                <MailComponent/>
                <InvitedStudentList/>
                <StudentList/>

            </div>
        </>
    );
}

export default Dashboard;
