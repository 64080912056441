/* eslint-disable no-template-curly-in-string */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {createStore} from 'redux';
import allReducers from './reducers';
const globalStore = createStore(
    allReducers
);
ReactDOM.render(
    <Provider store={globalStore}>
        <App/>
    </Provider>,
    document.getElementById('spacepandamath-center')
);
serviceWorker.unregister();
